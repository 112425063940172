import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getElectricbusesApiElectricbusesGet: build.query<
      GetElectricbusesApiElectricbusesGetApiResponse,
      GetElectricbusesApiElectricbusesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses`,
        params: {
          sorted_field: queryArg.sortedField,
          sorted_by: queryArg.sortedBy,
          type_id: queryArg.typeId,
          model_ids: queryArg.modelIds,
          manufacture_ids: queryArg.manufactureIds,
          status_ev_ids: queryArg.statusEvIds,
          group_ids: queryArg.groupIds,
          soc_from: queryArg.socFrom,
          soc_to: queryArg.socTo,
          soh_from: queryArg.sohFrom,
          soh_to: queryArg.sohTo,
          mileage_from: queryArg.mileageFrom,
          mileage_to: queryArg.mileageTo,
          power_reserve_from: queryArg.powerReserveFrom,
          power_reserve_to: queryArg.powerReserveTo,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    createElectricbusApiElectricbusesPost: build.mutation<
      CreateElectricbusApiElectricbusesPostApiResponse,
      CreateElectricbusApiElectricbusesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateElectricbusApiElectricbusesPatch: build.mutation<
      UpdateElectricbusApiElectricbusesPatchApiResponse,
      UpdateElectricbusApiElectricbusesPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    deleteElectricbusApiElectricbusesEvIdDelete: build.mutation<
      DeleteElectricbusApiElectricbusesEvIdDeleteApiResponse,
      DeleteElectricbusApiElectricbusesEvIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/electricbuses/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getElectricbusByGroupIdSrvElectricbusesGroupGroupIdGet: build.query<
      GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiResponse,
      GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/srv/electricbuses/group/${queryArg}` }),
    }),
    getElectricbusByIdSrvElectricbusesEvIdGet: build.query<
      GetElectricbusByIdSrvElectricbusesEvIdGetApiResponse,
      GetElectricbusByIdSrvElectricbusesEvIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/electricbuses/${queryArg.evId}`,
        params: { is_with_tracker: queryArg.isWithTracker },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetElectricbusesApiElectricbusesGetApiResponse =
  /** status 200 Successful Response */ PageElectricBusWithTrackerOutput;
export type GetElectricbusesApiElectricbusesGetApiArg = {
  sortedField?: ElectricBusSortEnum;
  sortedBy?: SortDirection;
  /** ID type vehicle. Default: ElectricBusType */
  typeId?: number;
  /** List IDs model vehicles */
  modelIds?: string;
  /** List IDs manufacture */
  manufactureIds?: string;
  /** List IDs status vehicle */
  statusEvIds?: string;
  /** List IDs group */
  groupIds?: string;
  socFrom?: number;
  socTo?: number;
  sohFrom?: number;
  sohTo?: number;
  mileageFrom?: number;
  mileageTo?: number;
  powerReserveFrom?: number;
  powerReserveTo?: number;
  /** Page number */
  page?: number;
  /** Page size */
  size?: number;
};
export type CreateElectricbusApiElectricbusesPostApiResponse =
  /** status 201 Successful Response */ ElectricBusOutput;
export type CreateElectricbusApiElectricbusesPostApiArg = ElectricBusCreate;
export type UpdateElectricbusApiElectricbusesPatchApiResponse =
  /** status 200 Successful Response */ ElectricBusOutput;
export type UpdateElectricbusApiElectricbusesPatchApiArg = ElectricBusUpdate;
export type DeleteElectricbusApiElectricbusesEvIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteElectricbusApiElectricbusesEvIdDeleteApiArg = string;
export type GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiResponse =
  /** status 200 Successful Response */ ElectricBusForGroupOutput[] | null;
export type GetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetApiArg =
  number;
export type GetElectricbusByIdSrvElectricbusesEvIdGetApiResponse =
  /** status 200 Successful Response */ ElectricBusWithTrackerOutput;
export type GetElectricbusByIdSrvElectricbusesEvIdGetApiArg = {
  evId: string;
  isWithTracker?: boolean;
};
export type StatusTracker = 'ONLINE' | 'OFFLINE';
export type TrackerOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  firmware?: string | null;
  ev_id: string;
  ev_type_id: number;
  phone_number?: string | null;
  last_online?: string | null;
};
export type ElectricBusWithTrackerOutput = {
  soc?: number | null;
  soh?: number | null;
  id: string;
  grn: string;
  garage_number: number;
  type_id: number;
  group_id: number;
  model_id: number;
  manufacture_id: number;
  status_id: number;
  last_charge_time?: string | null;
  tracker?: TrackerOutput | null;
};
export type PageElectricBusWithTrackerOutput = {
  items: ElectricBusWithTrackerOutput[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ElectricBusSortEnum =
  | 'type_id'
  | 'model_id'
  | 'manufacture_id'
  | 'status_id'
  | 'grn'
  | 'garage_number';
export type SortDirection = 'asc' | 'desc';
export type ElectricBusOutput = {
  id: string;
  grn: string;
  garage_number: number;
  type_id: number;
  group_id: number;
  model_id: number;
  manufacture_id: number;
  status_id: number;
  last_charge_time?: string | null;
};
export type ElectricBusCreate = {
  grn: string;
  garage_number: number;
  type_id?: number;
  model_id: number;
  group_id: number;
  manufacture_id: number;
  status_id?: number | null;
};
export type ElectricBusUpdate = {
  id: string;
  grn?: string | null;
  garage_number?: number | null;
  type_id?: number | null;
  model_id?: number | null;
  group_id?: number | null;
  manufacture_id?: number | null;
  status_id?: number | null;
};
export type ElectricBusForGroupOutput = {
  id: string;
  grn: string;
  garage_number: number;
  type_id: number;
  group_id: number;
  model_id: number;
  manufacture_id: number;
  status_id: number;
  last_charge_time?: string | null;
};
export const {
  useGetElectricbusesApiElectricbusesGetQuery,
  useCreateElectricbusApiElectricbusesPostMutation,
  useUpdateElectricbusApiElectricbusesPatchMutation,
  useDeleteElectricbusApiElectricbusesEvIdDeleteMutation,
  useGetElectricbusByGroupIdSrvElectricbusesGroupGroupIdGetQuery,
  useGetElectricbusByIdSrvElectricbusesEvIdGetQuery,
} = injectedRtkApi;
