import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHotStorageApiHotStorageGet: build.query<
      GetHotStorageApiHotStorageGetApiResponse,
      GetHotStorageApiHotStorageGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/hot_storage`,
        params: {
          park_id: queryArg.parkId,
          type_id: queryArg.typeId,
          soc_from: queryArg.socFrom,
          soc_to: queryArg.socTo,
          soh_from: queryArg.sohFrom,
          soh_to: queryArg.sohTo,
          ev_id: queryArg.evId,
          model_ids: queryArg.modelIds,
          manufacture_ids: queryArg.manufactureIds,
          status_ids: queryArg.statusIds,
          group_ids: queryArg.groupIds,
        },
      }),
    }),
    getTelemetriesApiTelemetriesGet: build.query<
      GetTelemetriesApiTelemetriesGetApiResponse,
      GetTelemetriesApiTelemetriesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/telemetries`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          sorted_field: queryArg.sortedField,
          sorted_by: queryArg.sortedBy,
        },
      }),
    }),
    getBatteryTelemetriesApiTelemetriesBatteryGet: build.query<
      GetBatteryTelemetriesApiTelemetriesBatteryGetApiResponse,
      GetBatteryTelemetriesApiTelemetriesBatteryGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/telemetries/battery`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          sorted_field: queryArg.sortedField,
          sorted_by: queryArg.sortedBy,
        },
      }),
    }),
    getTelemetryByFilterSrvTelemetriesGet: build.query<
      GetTelemetryByFilterSrvTelemetriesGetApiResponse,
      GetTelemetryByFilterSrvTelemetriesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/telemetries`,
        params: {
          soc_from: queryArg.socFrom,
          soc_to: queryArg.socTo,
          soh_from: queryArg.sohFrom,
          soh_to: queryArg.sohTo,
          power_reserve_from: queryArg.powerReserveFrom,
          power_reserve_to: queryArg.powerReserveTo,
          is_with_tracker: queryArg.isWithTracker,
        },
      }),
    }),
    getTelemetryByFilterSrvTelemetriesImeiGet: build.query<
      GetTelemetryByFilterSrvTelemetriesImeiGetApiResponse,
      GetTelemetryByFilterSrvTelemetriesImeiGetApiArg
    >({
      query: (queryArg) => ({ url: `/srv/telemetries/${queryArg}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetHotStorageApiHotStorageGetApiResponse =
  /** status 200 Successful Response */ HotStorageWithExtentFields[] | null;
export type GetHotStorageApiHotStorageGetApiArg = {
  parkId: string;
  typeId?: number | null;
  socFrom?: number | null;
  socTo?: number | null;
  sohFrom?: number | null;
  sohTo?: number | null;
  evId?: string | null;
  modelIds?: string | null;
  manufactureIds?: string | null;
  statusIds?: string | null;
  groupIds?: string | null;
};
export type GetTelemetriesApiTelemetriesGetApiResponse =
  /** status 200 Successful Response */ TelemetryBaseOutput[] | null;
export type GetTelemetriesApiTelemetriesGetApiArg = {
  page?: number;
  perPage?: number;
  sortedField?: TelemetrySortEnum;
  sortedBy?: SortDirection;
};
export type GetBatteryTelemetriesApiTelemetriesBatteryGetApiResponse =
  /** status 200 Successful Response */
    | BatteryTelemetryWithErrorsOutput[]
    | null;
export type GetBatteryTelemetriesApiTelemetriesBatteryGetApiArg = {
  page?: number;
  perPage?: number;
  sortedField?: BatteryTelemetrySortEnum;
  sortedBy?: SortDirection;
};
export type GetTelemetryByFilterSrvTelemetriesGetApiResponse =
  /** status 200 Successful Response */
    | (TelemetryWithTrackerOutput | TelemetryBaseOutput)[]
    | null;
export type GetTelemetryByFilterSrvTelemetriesGetApiArg = {
  socFrom?: number;
  socTo?: number;
  sohFrom?: number;
  sohTo?: number;
  powerReserveFrom?: number;
  powerReserveTo?: number;
  isWithTracker?: boolean;
};
export type GetTelemetryByFilterSrvTelemetriesImeiGetApiResponse =
  /** status 200 Successful Response */ TelemetryBaseOutput;
export type GetTelemetryByFilterSrvTelemetriesImeiGetApiArg = string;
export type EvSchema = {
  id: string;
  type_id?: number | null;
  group_id?: number | null;
  model_id?: number | null;
  manufacture_id?: number | null;
  status_id?: number | null;
  grn?: string | null;
};
export type HotStorageWithExtentFields = {
  time: string;
  latitude: number;
  pre_latitude?: number | null;
  longitude: number;
  pre_longitude?: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  power_reserve: number | null;
  charge_current_A: number | null;
  discharge_current_A: number | null;
  permissible_charge_current: number | null;
  permissible_discharge_current: number | null;
  last_charge: string | null;
  ctts: number | null;
  count_charge_session: number | null;
  interval_telemetry_S: number | null;
  ev?: EvSchema | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type TelemetryBaseOutput = {
  time: string;
  latitude: number;
  pre_latitude?: number | null;
  longitude: number;
  pre_longitude?: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  power_reserve: number | null;
  charge_current_A: number | null;
  discharge_current_A: number | null;
  permissible_charge_current: number | null;
  permissible_discharge_current: number | null;
  last_charge: string | null;
  ctts: number | null;
  count_charge_session: number | null;
  interval_telemetry_S: number | null;
};
export type TelemetrySortEnum =
  | 'time'
  | 'tracker_imei'
  | 'soc'
  | 'soh'
  | 'power_reserve'
  | 'charge_current_A'
  | 'discharge_current_A'
  | 'permissible_charge_current'
  | 'permissible_discharge_current';
export type SortDirection = 'asc' | 'desc';
export type BatteryTelemetryErrorBaseOutput = {
  code_error: number;
  text_error: string;
  battery_telemetry_id: number;
};
export type BatteryTelemetryWithErrorsOutput = {
  number_battery: number;
  min_voltage_V: number;
  max_voltage_V: number;
  avg_voltage_V: number;
  min_temperature_C: number;
  max_temperature_C: number;
  coolant_temp_C: number;
  battery_main_contactor: number;
  battery_state: number;
  firmware_bms: number | null;
  telemetry_id: number;
  errors?: BatteryTelemetryErrorBaseOutput[] | null;
};
export type BatteryTelemetrySortEnum =
  | 'min_voltage_V'
  | 'max_voltage_V'
  | 'avg_voltage_V'
  | 'min_temperature_C'
  | 'max_temperature_C'
  | 'coolant_temp_C'
  | 'battery_main_contactor'
  | 'battery_state'
  | 'firmware_bms';
export type StatusTracker = 'ONLINE' | 'OFFLINE';
export type TrackerInfoOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  serial_number: string;
  firmware?: string | null;
  ip_address?: string | null;
  ev_id: string;
  ev_type_id: number;
};
export type TelemetryWithTrackerOutput = {
  time: string;
  latitude: number;
  pre_latitude?: number | null;
  longitude: number;
  pre_longitude?: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  power_reserve: number | null;
  charge_current_A: number | null;
  discharge_current_A: number | null;
  permissible_charge_current: number | null;
  permissible_discharge_current: number | null;
  last_charge: string | null;
  ctts: number | null;
  count_charge_session: number | null;
  interval_telemetry_S: number | null;
  tracker?: TrackerInfoOutput | null;
};
export const {
  useGetHotStorageApiHotStorageGetQuery,
  useGetTelemetriesApiTelemetriesGetQuery,
  useGetBatteryTelemetriesApiTelemetriesBatteryGetQuery,
  useGetTelemetryByFilterSrvTelemetriesGetQuery,
  useGetTelemetryByFilterSrvTelemetriesImeiGetQuery,
} = injectedRtkApi;
