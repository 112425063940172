import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGroupsApiGroupsGet: build.query<
      GetGroupsApiGroupsGetApiResponse,
      GetGroupsApiGroupsGetApiArg
    >({
      query: () => ({ url: `/api/groups` }),
    }),
    getGroupByIdApiGroupsGroupIdGet: build.query<
      GetGroupByIdApiGroupsGroupIdGetApiResponse,
      GetGroupByIdApiGroupsGroupIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/groups/${queryArg}` }),
    }),
    getManufacturesApiVehiclesManufacturesGet: build.query<
      GetManufacturesApiVehiclesManufacturesGetApiResponse,
      GetManufacturesApiVehiclesManufacturesGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/manufactures` }),
    }),
    getManufacturesApiVehiclesModelsGet: build.query<
      GetManufacturesApiVehiclesModelsGetApiResponse,
      GetManufacturesApiVehiclesModelsGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/models` }),
    }),
    getStatusesApiVehiclesStatusesGet: build.query<
      GetStatusesApiVehiclesStatusesGetApiResponse,
      GetStatusesApiVehiclesStatusesGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/statuses` }),
    }),
    getStatusesApiVehiclesTypesGet: build.query<
      GetStatusesApiVehiclesTypesGetApiResponse,
      GetStatusesApiVehiclesTypesGetApiArg
    >({
      query: () => ({ url: `/api/vehicles/types` }),
    }),
    getGroupsApiTrackersModelsGet: build.query<
      GetGroupsApiTrackersModelsGetApiResponse,
      GetGroupsApiTrackersModelsGetApiArg
    >({
      query: () => ({ url: `/api/trackers/models` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetGroupsApiGroupsGetApiResponse =
  /** status 200 Successful Response */ GroupOutput[];
export type GetGroupsApiGroupsGetApiArg = void;
export type GetGroupByIdApiGroupsGroupIdGetApiResponse =
  /** status 200 Successful Response */ any;
export type GetGroupByIdApiGroupsGroupIdGetApiArg = number;
export type GetManufacturesApiVehiclesManufacturesGetApiResponse =
  /** status 200 Successful Response */ VehicleManufacturesRead[];
export type GetManufacturesApiVehiclesManufacturesGetApiArg = void;
export type GetManufacturesApiVehiclesModelsGetApiResponse =
  /** status 200 Successful Response */ VehicleModelsRead[];
export type GetManufacturesApiVehiclesModelsGetApiArg = void;
export type GetStatusesApiVehiclesStatusesGetApiResponse =
  /** status 200 Successful Response */ VehicleStatusesRead[];
export type GetStatusesApiVehiclesStatusesGetApiArg = void;
export type GetStatusesApiVehiclesTypesGetApiResponse =
  /** status 200 Successful Response */ VehicleTypesRead[];
export type GetStatusesApiVehiclesTypesGetApiArg = void;
export type GetGroupsApiTrackersModelsGetApiResponse =
  /** status 200 Successful Response */ TrackerModelOutput[];
export type GetGroupsApiTrackersModelsGetApiArg = void;
export type CounterpartyRead = {
  id: number;
  name: string;
  ogrn?: string | null;
  contract_number?: string | null;
};
export type GroupOutput = {
  id: number;
  name: string;
  parent_id?: number | null;
  counterparty?: CounterpartyRead | null;
  children?: GroupOutput[] | null;
  vehicles?: any[] | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type VehicleManufacturesRead = {
  id: number;
  name: string;
};
export type VehicleModelsRead = {
  id: number;
  name: string;
};
export type VehicleStatusesRead = {
  id: number;
  name: string;
};
export type VehicleTypesRead = {
  id: number;
  name: string;
};
export type TrackerModelOutput = {
  id: number;
  name: string;
};
export const {
  useGetGroupsApiGroupsGetQuery,
  useGetGroupByIdApiGroupsGroupIdGetQuery,
  useGetManufacturesApiVehiclesManufacturesGetQuery,
  useGetManufacturesApiVehiclesModelsGetQuery,
  useGetStatusesApiVehiclesStatusesGetQuery,
  useGetStatusesApiVehiclesTypesGetQuery,
  useGetGroupsApiTrackersModelsGetQuery,
} = injectedRtkApi;
