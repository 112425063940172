import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTrackersApiTrackersGet: build.query<
      GetTrackersApiTrackersGetApiResponse,
      GetTrackersApiTrackersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers`,
        params: {
          imei_like: queryArg.imeiLike,
          model_ids: queryArg.modelIds,
          statuses: queryArg.statuses,
          firmware: queryArg.firmware,
          sorted_field: queryArg.sortedField,
          sorted_by: queryArg.sortedBy,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    createTrackerApiTrackersPost: build.mutation<
      CreateTrackerApiTrackersPostApiResponse,
      CreateTrackerApiTrackersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateTrackersApiTrackersPatch: build.mutation<
      UpdateTrackersApiTrackersPatchApiResponse,
      UpdateTrackersApiTrackersPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getTrackersListApiTrackersListGet: build.query<
      GetTrackersListApiTrackersListGetApiResponse,
      GetTrackersListApiTrackersListGetApiArg
    >({
      query: () => ({ url: `/api/trackers/list` }),
    }),
    deleteTrackerApiTrackersImeiDelete: build.mutation<
      DeleteTrackerApiTrackersImeiDeleteApiResponse,
      DeleteTrackerApiTrackersImeiDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers/${queryArg}`,
        method: 'DELETE',
      }),
    }),
    getTrackerByImeiApiTrackersImeiGet: build.query<
      GetTrackerByImeiApiTrackersImeiGetApiResponse,
      GetTrackerByImeiApiTrackersImeiGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers/${queryArg.imei}`,
        params: { is_with_ev: queryArg.isWithEv },
      }),
    }),
    getFirmwaresApiFirmwaresGet: build.query<
      GetFirmwaresApiFirmwaresGetApiResponse,
      GetFirmwaresApiFirmwaresGetApiArg
    >({
      query: () => ({ url: `/api/firmwares` }),
    }),
    trackerCommandsApiTrackersCommandsPost: build.mutation<
      TrackerCommandsApiTrackersCommandsPostApiResponse,
      TrackerCommandsApiTrackersCommandsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers/commands`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    trackerCommandsSettingsApiTrackersCommandsSettingsPost: build.mutation<
      TrackerCommandsSettingsApiTrackersCommandsSettingsPostApiResponse,
      TrackerCommandsSettingsApiTrackersCommandsSettingsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/api/trackers/commands/settings`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getTrackerByEvIdSrvTrackersGet: build.query<
      GetTrackerByEvIdSrvTrackersGetApiResponse,
      GetTrackerByEvIdSrvTrackersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/trackers`,
        params: { ev_id: queryArg.evId, ev_type_id: queryArg.evTypeId },
      }),
    }),
    updateTrackerSrvTrackersPatch: build.mutation<
      UpdateTrackerSrvTrackersPatchApiResponse,
      UpdateTrackerSrvTrackersPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/trackers`,
        method: 'PATCH',
        body: queryArg,
      }),
    }),
    getTrackerByImeiSrvTrackersImeiGet: build.query<
      GetTrackerByImeiSrvTrackersImeiGetApiResponse,
      GetTrackerByImeiSrvTrackersImeiGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/trackers/${queryArg.imei}`,
        params: { is_with_ev: queryArg.isWithEv },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetTrackersApiTrackersGetApiResponse =
  /** status 200 Successful Response */ PageTrackerOutput;
export type GetTrackersApiTrackersGetApiArg = {
  imeiLike?: string | null;
  modelIds?: string | null;
  statuses?: string | null;
  firmware?: string | null;
  sortedField?: TrackerSortEnum;
  sortedBy?: SortDirection;
  /** Page number */
  page?: number;
  /** Page size */
  size?: number;
};
export type CreateTrackerApiTrackersPostApiResponse =
  /** status 201 Successful Response */ TrackerOutput;
export type CreateTrackerApiTrackersPostApiArg = TrackerCreate;
export type UpdateTrackersApiTrackersPatchApiResponse =
  /** status 200 Successful Response */ TrackerOutput;
export type UpdateTrackersApiTrackersPatchApiArg = TrackerUpdate;
export type GetTrackersListApiTrackersListGetApiResponse =
  /** status 200 Successful Response */ TrackerOutput[];
export type GetTrackersListApiTrackersListGetApiArg = void;
export type DeleteTrackerApiTrackersImeiDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteTrackerApiTrackersImeiDeleteApiArg = string;
export type GetTrackerByImeiApiTrackersImeiGetApiResponse =
  /** status 200 Successful Response */ TrackerOutput | TrackerWithEvOutput;
export type GetTrackerByImeiApiTrackersImeiGetApiArg = {
  imei: string;
  isWithEv?: boolean;
};
export type GetFirmwaresApiFirmwaresGetApiResponse =
  /** status 200 Successful Response */ string[] | null;
export type GetFirmwaresApiFirmwaresGetApiArg = void;
export type TrackerCommandsApiTrackersCommandsPostApiResponse =
  /** status 200 Successful Response */ any;
export type TrackerCommandsApiTrackersCommandsPostApiArg = TrackerCommandBase;
export type TrackerCommandsSettingsApiTrackersCommandsSettingsPostApiResponse =
  /** status 200 Successful Response */ any;
export type TrackerCommandsSettingsApiTrackersCommandsSettingsPostApiArg =
  TrackerCommandWithParams;
export type GetTrackerByEvIdSrvTrackersGetApiResponse =
  /** status 200 Successful Response */ TrackerOutput | null;
export type GetTrackerByEvIdSrvTrackersGetApiArg = {
  evId: string;
  evTypeId: number;
};
export type UpdateTrackerSrvTrackersPatchApiResponse =
  /** status 200 Successful Response */ TrackerOutput;
export type UpdateTrackerSrvTrackersPatchApiArg = TrackerUpdate;
export type GetTrackerByImeiSrvTrackersImeiGetApiResponse =
  /** status 200 Successful Response */ TrackerOutput | TrackerWithEvOutput;
export type GetTrackerByImeiSrvTrackersImeiGetApiArg = {
  imei: string;
  isWithEv?: boolean;
};
export type StatusTracker = 'ONLINE' | 'OFFLINE';
export type TrackerOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  ev_id?: string | null;
  ev_type_id?: number | null;
  firmware?: string | null;
  model_id?: number | null;
  phone_number?: string | null;
  last_online?: string | null;
};
export type PageTrackerOutput = {
  items: TrackerOutput[];
  total: number | null;
  page: number | null;
  size: number | null;
  pages?: number | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type TrackerSortEnum = 'imei' | 'status' | 'model_id' | 'firmware';
export type SortDirection = 'asc' | 'desc';
export type TrackerCreate = {
  imei: string;
  model_id?: number | null;
};
export type TrackerUpdate = {
  imei?: string | null;
  new_imei?: string | null;
  model_id?: number | null;
  firmware?: string | null;
  ev_id?: string | null;
  ev_type_id?: number | null;
  phone_number?: string | null;
  last_online?: string | null;
};
export type ElectricBusOutput = {
  id: string;
  grn: string;
  garage_number: number;
  type_id: number;
  model_id: number;
  group_id: number;
  manufacture_id: number;
  status_id: number;
};
export type TrackerWithEvOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  model_id?: number | null;
  firmware?: string | null;
  ev: ElectricBusOutput | null;
};
export type TrackerCommandEnum =
  | 'RESET'
  | 'REVERT'
  | 'INFO'
  | 'UPDATE_FIRMWARE'
  | 'UPDATE_INTERVAL'
  | 'UPDATE_MQTT';
export type TrackerCommandBase = {
  imeis: string[];
  command: TrackerCommandEnum;
};
export type TrackerCommandWithParams = {
  imeis: string[];
  command: TrackerCommandEnum;
  interval?: number | null;
  firmware?: string | null;
};
export const {
  useGetTrackersApiTrackersGetQuery,
  useCreateTrackerApiTrackersPostMutation,
  useUpdateTrackersApiTrackersPatchMutation,
  useGetTrackersListApiTrackersListGetQuery,
  useDeleteTrackerApiTrackersImeiDeleteMutation,
  useGetTrackerByImeiApiTrackersImeiGetQuery,
  useGetFirmwaresApiFirmwaresGetQuery,
  useTrackerCommandsApiTrackersCommandsPostMutation,
  useTrackerCommandsSettingsApiTrackersCommandsSettingsPostMutation,
  useGetTrackerByEvIdSrvTrackersGetQuery,
  useUpdateTrackerSrvTrackersPatchMutation,
  useGetTrackerByImeiSrvTrackersImeiGetQuery,
} = injectedRtkApi;
