import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { GetHotStorageApiHotStorageGetApiResponse } from '@shared/api/services/telemetry/api';
import { GetGroupsApiGroupsGetApiResponse } from '@shared/api/services/common/api';

import {
  selectVehicleFilters,
  setFilters,
} from '@shared/redux/slices/vehicle/filters';

import { useGetHotStorageTelemetryQuery } from '@shared/api/services/telemetry/enhanced';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { Telemetry } from '@entities/vehicle/model/telemetry';

import { ParkOfElectrobusesHeader } from '@widgets/header/ui/park-of-electrobuses-header';
import { Map } from '@widgets/vehicle/vehicles-map/map';
import { VehicleInfo } from '@widgets/vehicle/vehicle-info';

import { MapContainer, StyledMonitoringLayout } from './styles';
import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import { VehicleTableType } from '@entities/vehicle/model/vehicle-table-type';
import { preprocessGroupIdsArg } from '@entities/vehicle/lib/preprocess-group-ids-arg';
import { TRANSPORT } from '@shared/consts/routes';
import { getLngLat } from '@entities/vehicle/lib/get-lng-lat';
import { Spin } from 'antd';
import { ConvoyHeader } from '@widgets/header/ui/convoy';
import { ElectrobusPageHeader } from '@widgets/header/ui/electrobus-page-header';
import { getWebSocketURL } from '@shared/lib/web-socket/get-web-socket-url';
import { selectAccessToken } from '@shared/redux/slices/auth/storage-slice';
import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';
import { useGetStationsListQuery } from '@shared/api/services/integration/enhanced';
import { StationOutput } from '@shared/api/services/integration/api';
import { StationsInfo } from '@widgets/vehicle/stations-info';

const SOCKET_URL = getWebSocketURL();

export function ParkOfElectrobusesPage() {
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mapType, setMapType] = useState<VehicleTableType | null>(null);
  const [telemetry, setTelemetry] = useState<Record<string, Telemetry>>({});

  const { eParkId, convoyId, eBusId } = useParams();

  const filters = useAppSelector(selectVehicleFilters);

  const {
    groupIds,
    manufactureIds,
    modelsIds,
    statusEvIds,
    typeId,
    sohFrom,
    sohTo,
    socFrom,
    socTo,
    powerReserveFrom,
    powerReserveTo,
    mileageFrom,
    mileageTo,
  } = filters;

  // Добавить уведомление об обсутствии данных в редисе
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
    error: userDataError,
  } = useGetUsersInfoQuery();

  const {
    data: stationsList,
    isLoading: isStationsListLoading,
    isFetching: isStationsListFetching,
    error: stationsListError,
  } = useGetStationsListQuery(undefined, {
    pollingInterval: 5000,
  });

  const stationsToMap =
    stationsList?.filter(({ latitude, longitude }) => {
      return (
        latitude !== undefined &&
        latitude !== null &&
        longitude !== undefined &&
        longitude !== null
      );
    }) ?? [];

  const { data, isLoading, isFetching, error } = useGetHotStorageTelemetryQuery(
    {
      parkId: eParkId!,
      modelIds: modelsIds.length > 0 ? modelsIds.join(',') : undefined,
      typeId: typeId ?? undefined,
      manufactureIds:
        manufactureIds.length > 0 ? manufactureIds.join(',') : undefined,
      statusIds: statusEvIds.length > 0 ? statusEvIds.join(',') : undefined,
      socFrom: socFrom ?? undefined,
      socTo: socTo ?? undefined,
      sohFrom: sohFrom ?? undefined,
      sohTo: sohTo ?? undefined,
      evId: eBusId,
      groupIds: groupIds !== undefined ? String(groupIds) : undefined,
    }
  );

  const {
    data: parks,
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  useEffect(() => {
    if (eParkId && convoyId && eBusId) {
      setMapType('ELECTROBUS');
      return;
    }
    if (eParkId && convoyId && !eBusId) {
      setMapType('CONVOY');
      return;
    }
    if (eParkId && !convoyId && !eBusId) {
      setMapType('PARK');
      return;
    }
  }, [eParkId, convoyId, eBusId]);

  useEffect(() => {
    if (mapType && parks) {
      dispatch(
        setFilters({
          ...filters,
          groupIds: preprocessGroupIdsArg(
            mapType,
            groupIds,
            eParkId,
            convoyId,
            parks
          ),
          parkId: Number(eParkId),
        })
      );
    }
  }, [mapType, eParkId, convoyId, eBusId, parks]);

  const { lastMessage, lastJsonMessage, readyState, sendJsonMessage } =
    useWebSocket(SOCKET_URL);

  const handleSubscribe = useCallback(() => {
    if (userData?.username !== undefined) {
      sendJsonMessage({
        type: 'subscription',
        payload: {
          username: userData?.username,
          park_id: eParkId,
          token: accessToken,
        },
      });
    }
  }, [groupIds, userData?.username]);

  useEffect(() => {
    if (userData?.username !== undefined) {
      sendJsonMessage({
        type: 'subscription',
        payload: {
          username: userData.username,
          park_id: eParkId,
          token: accessToken,
        },
      });
    }
  }, [eParkId, groupIds, userData?.username]);

  useEffect(() => {
    if (data) {
      const latestTelemetry = data.reduce((acc, el) => {
        return { ...acc, [el.tracker_imei]: el };
      }, {} as Record<string, Telemetry>);

      setTelemetry(latestTelemetry);
    }
  }, [data]);

  useEffect(() => {
    if (readyState === 1 && data && userData?.username) {
      handleSubscribe();
    }
  }, [readyState, userData?.username]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const message = lastJsonMessage as Telemetry;

      if (
        message.tracker_imei &&
        data?.some(({ tracker_imei }) => tracker_imei === message.tracker_imei)
      ) {
        setTelemetry((prev) => {
          return { ...prev, [message.tracker_imei]: message };
        });
      }
    }
  }, [lastMessage, data]);

  const [selectedTrackerImei, setSelectedTrackerImei] = useState('');
  const [selectedStations, selectStations] = useState<StationOutput[]>([]);

  useEffect(() => {
    if (selectedTrackerImei.length > 0 && mapType === 'ELECTROBUS') {
      navigate(`${TRANSPORT}/${eParkId}/${convoyId}/${eBusId}/details`);
    }
  }, [selectedTrackerImei]);

  const handleSelectVehicle = (trackerImei: string) => {
    handleDeseleceStations();
    setSelectedTrackerImei(trackerImei);
  };

  const handleSelectStations = (stations: StationOutput[]) => {
    handleDeseleceVehicle();
    selectStations(stations);
  };

  const handleDeseleceVehicle = () => setSelectedTrackerImei('');

  const handleDeseleceStations = () => selectStations([]);

  const { initialLatitude, initialLongitude } = getLngLat({
    mapType,
    parks,
    data,
    eParkId,
    convoyId,
    eBusId,
  });

  return (
    <StyledMonitoringLayout>
      <Spin spinning={isFetching || isLoading || isStationsListLoading}>
        <MapContainer>
          <Map
            stationsData={stationsToMap ?? []}
            selectStations={handleSelectStations}
            selectedTrackerImei={
              mapType === 'ELECTROBUS' ? undefined : selectedTrackerImei
            }
            onSelectVehicle={handleSelectVehicle}
            telemetry={isFetching || isLoading ? [] : Object.values(telemetry)}
            zoomLevel={15}
            initialLatitude={initialLatitude}
            initialLongitude={initialLongitude}
          />
          {mapType === 'PARK' && (
            <ParkOfElectrobusesHeader
              showVehicleInfo={
                Boolean(selectedTrackerImei) || Boolean(selectedStations.length)
              }
            />
          )}
          {mapType === 'CONVOY' && (
            <ConvoyHeader
              showVehicleInfo={
                Boolean(selectedTrackerImei) || Boolean(selectedStations.length)
              }
            />
          )}
          {mapType === 'ELECTROBUS' && (
            <ElectrobusPageHeader
              showVehicleInfo={
                Boolean(selectedTrackerImei) || Boolean(selectedStations.length)
              }
            />
          )}
          <VehicleInfo
            selectedTrackerTelemetry={telemetry[selectedTrackerImei]}
            handleClose={handleDeseleceVehicle}
          />
          <StationsInfo
            handleClose={handleDeseleceStations}
            stations={selectedStations}
          />
        </MapContainer>
      </Spin>
    </StyledMonitoringLayout>
  );
}
